import React from "react";
import ItemDocuments from "../ItemDocuments";

export default function SoftwareInfo() {
	return (
		<div className="software-info">
			<section className="section">
				<div className="container">
					<div className="software-info__content">
						<ItemDocuments></ItemDocuments>
					</div>
				</div>
			</section>
		</div>
	);
}
