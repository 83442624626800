import React, { useEffect, useState, useRef } from "react";
import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion";
import anime from "animejs";

const valueAnimation = {
	started: {
		scale: 1,
		translateY: "50%"
	},
	finished: {
		scale: 0.9,
		translateY: 0
	}
};

const sloganAnimation = {
	finished: {
		opacity: 1,
		scale: 1,
		translateY: 0
	},
	started: {
		scale: 0.9,
		opacity: 0,
		translateY: "-100%"
	}
};

const textAnimation = {
	finished: {
		opacity: 1,
		translateY: 0
	},
	started: {
		opacity: 0,
		translateY: "-100%"
	}
};

const millisecondsAnimation = {
	visible: {
		opacity: 1
	},
	hidden: {
		opacity: 0
	}
};

export default function TimeAccuracy() {
	const [viewRef, inView] = useInView();
	const [value, setvalue] = useState(10);
	const [completed, setCompleted] = useState(false);

	const ref = useRef({
		value
	});

	const animeInstance = useRef(
		anime({
			targets: ref.current,
			value: 0.001,
			round: 100000,
			duration: 5000,
			autoplay: false,
			easing: "easeInSine",
			update: () => {
				setvalue(ref.current.value.toFixed(3));
			},
			begin: () => {
				setCompleted(false);
			},
			complete: () => {
				setCompleted(true);
			}
		})
	);

	useEffect(() => {
		if (inView) animeInstance.current.play();
		return () => {
			animeInstance.current.pause();
		};
	}, [inView]);

	return (
		<div className="time-accuracy" ref={viewRef}>
			<section className="section">
				<div className="container">
					<motion.div className="time-accuracy__time" variants={valueAnimation} initial={"started"} animate={completed ? "finished" : "started"} transition={{ type: "tween" }}>
						<div className="time-accuracy__value">{value}</div>
						<motion.div
							variants={millisecondsAnimation}
							initial={"hidden"}
							animate={completed ? "visible" : "hidden"}
							transition={{ type: "tween" }}
							className="time-accuracy__unit"
						>
							миллисекунда
						</motion.div>
					</motion.div>
					<motion.div className="time-accuracy__slogan" variants={sloganAnimation} initial={"started"} animate={completed ? "finished" : "started"} transition={{ type: "tween" }}>
						Предел допускаемой абсолютной погрешности синхронизации внутренней шкалы времени относительно шкалы UTC
					</motion.div>

					<motion.div className="time-accuracy__text" variants={textAnimation} initial={"started"} animate={completed ? "finished" : "started"} transition={{ type: "tween" }}>
						Данный показатель обеспечивают непревзойденную точность вычислений скорости транспортного средства в плане по видеокадру
					</motion.div>
				</div>
			</section>
		</div>
	);
}
