import React, { useState } from "react";
import { withNaming } from "@bem-react/classname";
import { motion } from "framer-motion";
import Model from "./Model";

const viewModel = withNaming({ e: "__", m: "_", v: "_" })("view-model");

const animate3d = {
	rotateX: ["-5deg", "5deg", "-5deg"],
	rotateY: ["-3deg", "3deg", "-3deg"]
};

const animateLoad = {
	strokeDasharray: ["5, 0, 0", "5, 0, 3", "8, 0, 5", "0, 0, 5", "3, 0, 0"]
};

function template({ rotateX, rotateY }) {
	return `perspective(10px) rotateX(${rotateX}) rotateY(${rotateY})`;
}

export default function ViewModel() {
	const [state, setstate] = useState(false);
	const [complete, setcomplete] = useState(false);

	return (
		<div className="view-model">
			<section className="section">
				<div className="container">
					<motion.div
						className={viewModel("content", {
							active: state
						})}
						layoutTransition={{ type: "tween" }}
						onAnimationComplete={() => setcomplete(true)}
					>
						{/* <div className="view-model__aurora-case"></div> */}
						<motion.div
							className={viewModel("container", {
								active: state
							})}
							layoutTransition={{ type: "tween" }}
						>
							{complete && (
								<div className="view-model__model">
									<Model></Model>
								</div>
							)}

							{!state && (
								<div className="view-model__container-left">
									<div className="view-model__container-icon">
										<motion.div
											className="view-model__container-icon-image"
											animate={animate3d}
											transformTemplate={template}
											transition={{ duration: 3, loop: Infinity }}
										></motion.div>
									</div>

									<div className="view-model__container-title">Давно выяснено, что при оценке дизайна и композиции читаемый текст мешает сосредоточиться</div>
									<div className="view-model__container-subtitle">Давно выяснено, что при оценке дизайна и композиции</div>
								</div>
							)}
							{!state && (
								<div className="view-model__container-right">
									<div className="view-model__load-button" onClick={() => setstate(!state)}>
										<motion.svg viewBox="0 0 22 30" className="view-model__load-button-icon">
											<motion.g transform="matrix(1.37457,0,0,1.37457,-4.29691,0)">
												<motion.path d="M16.791,13.254C17.235,12.81 17.934,12.81 18.378,13.254C18.807,13.698 18.807,14.397 18.378,14.841L11.728,21.492C11.522,21.698 11.236,21.825 10.919,21.825C10.602,21.825 10.316,21.698 10.109,21.492L3.459,14.841C3.015,14.397 3.015,13.698 3.459,13.254C3.903,12.81 4.602,12.81 5.046,13.254L9.792,18.016L9.792,1.111C9.791,0.492 10.299,0 10.918,0C11.537,0 12.029,0.492 12.029,1.111L12.029,18.015L16.791,13.254Z"></motion.path>
											</motion.g>
										</motion.svg>

										<motion.svg className="view-model__load-button-container">
											<motion.circle
												cx="45"
												cy="45"
												r="40"
												animate={animateLoad}
												transition={{ ease: "easeInOut", duration: 5, loop: Infinity }}
												className="view-model__load-button-ring"
											></motion.circle>
										</motion.svg>
									</div>
								</div>
							)}
						</motion.div>
					</motion.div>
				</div>
			</section>
		</div>
	);
}
