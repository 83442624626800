import React from "react";
import ReactDOM from "react-dom";
import Application from "./Application";
import "./assets/scss/main.scss";
import { Provider } from "mobx-react";
import NiesStore from "./store/";

// import * as serviceWorker from './serviceWorker';

ReactDOM.render(
	<Provider store={NiesStore}>
		<Application />
	</Provider>,
	document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
