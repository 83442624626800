import React from "react";
import ItemDocument from "../ItemDocument";

export default function ItemDocuments(props) {
	return (
		<div className="item-documents">
			<div className="item-documents__content">
				<div className="item-documents__primary">
					<ItemDocument
						type="primary"
						title="Документация"
						subtitle="В этом разделе содержится описание основных характеристик программного комплекса, информация по его установке и данные о стоимости"
						files={[
							{
								link: "CORTES Общие данные.pdf",
								tag: "CORTES Основные данные",
							},
							{
								link: "CORTES Информация о стоимости.pdf",
								tag: "CORTES Информация о стоимости",
							},
						]}
					></ItemDocument>
				</div>
			</div>
		</div>
	);
}
