import React from "react";
import PropTypes from "prop-types";
import { withNaming } from "@bem-react/classname";

const serviceIcon = withNaming({ e: "__", m: "_", v: "_" })("company-service");

function CompanyService(props) {
	return (
		<div className="company-service">
			<div
				className={serviceIcon("icon", {
					[props.type]: true
				})}
			></div>
			<div className="company-service__title">{props.title}</div>
		</div>
	);
}

CompanyService.propTypes = {
	title: PropTypes.string.isRequired,
	type: PropTypes.string.isRequired
};

export default CompanyService;
