import React from "react";

export default function DeviceDescription() {
	return (
		<div className="device-description">
			<section className="section">
				<div className="container">
					<div className="device-description__content">
						<div className="device-description__title">Давно выяснено, что при оценке дизайна и композиции читаемый текст мешает сосредоточиться</div>
						<div className="device-description__description">
							Lorem Ipsum используют потому, что тот обеспечивает более или менее стандартное заполнение шаблона, а также реальное распределение букв и пробелов в абзацах, которое
							не получается при простой дубликации "Здесь ваш текст.. Здесь ваш текст.. Здесь ваш текст.
						</div>
					</div>
				</div>
			</section>
		</div>
	);
}
