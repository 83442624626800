import React, { Component } from "react";
import { YMaps, Map, Placemark } from "react-yandex-maps";

export default class CompanyLocation extends Component {
	render() {
		return (
			<div className="company-location">
				<section className="section">
					<div className="container">
						<div className="company-location__content">
							<div className="company-location__info">
								<div className="company-location__info-section company-location__info-section_contacts">
									<a href="tel:+79990657321" className="company-location__info-content company-location__info-content_number">
										+7 (999) 065-73-21
									</a>

									<div className="company-location__info-item">
										<div className="company-location__info-icon company-location__info-icon_mail"></div>

										<a href="mailto:" className="company-location__info-content company-location__info-content_email">
											info@nies.su
										</a>
									</div>
								</div>

								<div className="company-location__info-section company-location__info-section_location">
									<div className="company-location__info-item">
										<div className="company-location__info-icon company-location__info-icon_location"></div>

										<div className="company-location__info-content company-location__info-content_address">ул. Учительская, д.23, лит.А, офис 439</div>
									</div>
								</div>
							</div>
							<YMaps>
								<Map defaultState={{ center: [60.032587, 30.383679], zoom: 13, maxZoom: 15, minZoom: 10 }} className="company-location__map">
									<Placemark
										geometry={[60.031728, 30.403625]}
										options={{
											iconLayout: "default#image",
											iconImageHref: "placeholder.svg",
											iconImageSize: [40, 40]
										}}
									></Placemark>
								</Map>
							</YMaps>
						</div>
					</div>
				</section>
			</div>
		);
	}
}
