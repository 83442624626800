import React from "react";
import PropTypes from "prop-types";
import { withNaming } from "@bem-react/classname";

const sectionHeader = withNaming({ e: "__", m: "_", v: "_" })("section-header");

export default function SectionHeader({ color = "white", align = "center", size = "medium", title, description }) {
	return (
		<div
			className={sectionHeader({
				[color]: true,
				[align]: true,
				[size]: true
			})}
		>
			<div className={sectionHeader("title")}>{title}</div>
			{description && <div className={sectionHeader("description")}>{description}</div>}
		</div>
	);
}

SectionHeader.propTypes = {
	color: PropTypes.string,
	align: PropTypes.string,
	size: PropTypes.string,
	title: PropTypes.string.isRequired,
	description: PropTypes.string
};
