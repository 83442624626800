import React, { useRef, useLayoutEffect, useState } from "react";
import { motion, useTransform, useViewportScroll } from "framer-motion";

export default function AuroraHeader() {
	const [elementPosition, setElementPosition] = useState({
		top: null,
		bottom: null
	});
	const ref = useRef(null);
	let progress = useViewportScroll();
	let transformed = useTransform(progress.scrollY, [elementPosition.top, elementPosition.bottom], [0, 100]);
	let backgroundColor = useTransform(transformed, [0, 70], ["rgba(0, 0, 0, 0.8)", "rgba(245, 245, 245, 1)"]);
	let sloganOpacity = useTransform(transformed, [0, 30], [1, 0]);

	useLayoutEffect(() => {
		setElementPosition({
			top: ref.current.offsetTop - window.innerHeight * 0.1,
			bottom: ref.current.offsetTop + ref.current.clientHeight - window.innerHeight * 0.1
		});
	}, [ref]);

	return (
		<div ref={ref} className="aurora-header">
			<motion.div
				className="aurora-header__background"
				style={{
					backgroundColor: backgroundColor
				}}
			>
				<motion.div
					className="aurora-header__title"
					style={{
						opacity: sloganOpacity
					}}
				>
					Многофункциональность и эффективность
				</motion.div>
				<div className="aurora-header__image"></div>
			</motion.div>
		</div>
	);
}
