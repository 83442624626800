import React from "react";
import { motion } from "framer-motion";

export default function Line() {
	return (
		<motion.svg viewBox="0 0 2000 10" preserveAspectRatio="none" className="average-speed__line">
			<motion.line
				x1="0"
				x2="2000"
				y1="0"
				y2="0"
				initial={{
					strokeDashoffset: 0,
				}}
				animate={{
					strokeDashoffset: 2000,
				}}
				transition={{
					ease: "linear",
					duration: 3,
					loop: Infinity,
				}}
			></motion.line>
		</motion.svg>
	);
}
