import { types } from "mobx-state-tree";

const feedbackModel = types.model("Feedback", {
	firstName: types.string,
	lastName: types.string,
	number: types.string,
	email: types.string,
	text: types.string
});

export default feedbackModel;