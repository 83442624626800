import React, { useLayoutEffect, useState, useRef } from "react";
import { motion } from "framer-motion";
import range from "lodash/range";
import Swiper from "react-id-swiper";
import "swiper/css/swiper.css";
import Card from "./Card";

export default function AvailableViolations() {
	const ref = useRef(null);
	const [state, setstate] = useState(null);

	useLayoutEffect(() => {
		setstate(ref.current.clientWidth);
	}, []);

	return (
		<div className="available-violations">
			<section className="section available-violations__section">
				<div ref={ref} className="available-violations__container">
					<div className="available-violations__line">
						<Swiper
							speed={2000}
							// autoplay={{
							// 	delay: 0,
							// 	disableOnInteraction: false
							// }}
							slidesPerView="auto"
							freeMode={true}
							loop={true}
						>
							{range(5).map(() => (
								<div className="available-violations__slide">
									<Card></Card>
								</div>
							))}
						</Swiper>
					</div>
					<div className="available-violations__line">
						<Swiper
							speed={2000}
							// autoplay={{
							// 	delay: 0,
							// 	disableOnInteraction: false,
							// 	reverseDirection: true
							// }}
							slidesPerView="auto"
							freeMode={true}
							loop={true}
						>
							{range(10).map(() => (
								<div className="available-violations__slide">
									<Card></Card>
								</div>
							))}
						</Swiper>
					</div>
				</div>
			</section>
		</div>
	);
}
