import React from "react";
import range from "lodash/range";

export default function DeviceCamera() {
	return (
		<div className="device-camera">
			<section className="section">
				<div className="container">
					<div className="device-camera__header">
						<div className="device-camera__header-title">Давно выяснено, что при оценке дизайна и композиции читаемый текст мешает сосредоточиться</div>
					</div>
					<div className="device-camera__description">
						Измерительно-регистрирующий модуль в непрерывном режиме производит измерение параметров движения и фотографирование транспортных средств а также самостоятельно
						производит обработку данных и передачу материалов по каналам связи
					</div>
					<div className="device-camera__features device-camera__features_general">
						<div className="device-camera__feature device-camera__feature_general">
							<div className="device-camera__feature-text device-camera__feature-text_bold">До</div>
							<div className="device-camera__feature-group">
								<div className="device-camera__feature-icon device-camera__feature-icon_fps"></div>
								<div className="device-camera__feature-title">40 FPS</div>
							</div>
							<div className="device-camera__feature-text ">Скорость сьемки камеры</div>
						</div>

						<div className="device-camera__feature device-camera__feature_general">
							<div className="device-camera__feature-group">
								<div className="device-camera__feature-icon device-camera__feature-icon_fps"></div>
								<div className="device-camera__feature-title">40 FPS</div>
							</div>
							<div className="device-camera__feature-text ">Скорость сьемки камеры</div>
						</div>

						<div className="device-camera__feature device-camera__feature_general">
							<div className="device-camera__feature-group">
								<div className="device-camera__feature-icon device-camera__feature-icon_fps"></div>
								<div className="device-camera__feature-title">40 FPS</div>
							</div>
							<div className="device-camera__feature-text ">Скорость сьемки камеры</div>
						</div>
					</div>
				</div>
			</section>
		</div>
	);
}
