import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { AnimatePresence } from "framer-motion";

import Home from "./screens/Home";
import Company from "./screens/Company";
import Cortes from "./screens/Cortes";
import Aurora from "./screens/Aurora";
import ScrollToTop from "./components/ScrollToTop";

import Test from "./components/Test";

export default function Application() {
	return (
		<Router>
			<ScrollToTop></ScrollToTop>
			<AnimatePresence exitBeforeEnter>
				<Switch>
					<Route exact path="/" component={Home}></Route>
					<Route path="/company" component={Company}></Route>
					<Route path="/cortes" component={Cortes}></Route>
					<Route path="/aurora" component={Aurora}></Route>
					<Route path="/test" component={Test}></Route>
				</Switch>
			</AnimatePresence>
		</Router>
	);
}
