import React from "react";
import { motion, useInvertedScale } from "framer-motion";

export default function Success() {
	let inverse = useInvertedScale();

	return (
		<motion.div
			className="feedback-form__success"
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			exit={{ opacity: 0 }}
			transition={{
				duration: 0.5
			}}
			style={{ ...inverse }}
		>
			<div className="feedback-form__success-icon"></div>
			<div className="feedback-form__success-title">Lorem Ipsum - это текст-"рыба"</div>
			<div className="feedback-form__success-description">Давно выяснено, что при оценке дизайна и композиции читаемый текст мешает сосредоточиться</div>
		</motion.div>
	);
}
