import React from "react";
import SectionHeader from "../SectionHeader";

export default function DownloadSoftware() {
	return (
		<div className="download-software">
			<section className="section">
				<div className="container">
					<SectionHeader
						title="Загрузите дистрибутив Cortes и протестируйте заявленный функционал"
						description="Для загрузки рекомендуется подключение к интернету на скорости не менее 10 Мбит/с."
						color="white"
						align="left"
					></SectionHeader>

					<div className="download-software__container">
						<div className="download-software__content">
							<div className="download-software__content-left">
								{/* <div className="download-software__content-logo"></div> */}
								<div className="download-software__content-title">Загрузите последнюю версию программного комплекса Cortes</div>

								<div className="download-software__download">
									<a className="download-software__download-button" href="https://nies.su/cortes-0.0.1-x64.tgz.gpg">
										Скачать
									</a>
									<div className="download-software__download-hash">MD5: 80B5FF2807DF72092679F6EDBAD58DE2</div>
								</div>
							</div>

							<div className="download-software__content-right">
								<div className="download-software__content-description">
									Мы рады, что вы решили попробовать Cortes. Следуйте пунктам снизу чтобы максимально быстро и правильно инсталлировать программное обеспечение
								</div>

								<div className="download-software__steps">
									<div className="download-software__step">
										<div className="download-software__step-icon">1</div>
										<div className="download-software__step-text">После загрузки образа обязательно проверьте его целостность</div>
									</div>
									<div className="download-software__step">
										<div className="download-software__step-icon">2</div>
										<div className="download-software__step-text">Распакуйте образ ПО с помощью ключа, полученного по электронной почте</div>
									</div>
									<div className="download-software__step">
										<div className="download-software__step-icon">3</div>
										<div className="download-software__step-text">Следуйте шагам, приведенным в пункте 1.3 руководства пользователя</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
	);
}
