import React from "react";

export default function AuroraNavigation() {
	return (
		<div className="aurora-navigation">
			<div className="aurora-navigation__background"></div>
			<section className="section">
				<div className="container">
					<div className="aurora-navigation__content">
						<div className="aurora-navigation__content-left">
							<div className="aurora-navigation__text">
								<div className="aurora-navigation__title">Lorem Ipsum - это текст-"рыба"</div>
								<div className="aurora-navigation__description">
									В то время некий безымянный печатник создал большую коллекцию размеров и форм шрифтов, используя Lorem Ipsum для распечатки образцов. Lorem Ipsum не только
									успешно пережил без заметных изменений пять веков, но и перешагнул в электронный дизайн
								</div>
							</div>
						</div>
						<div className="aurora-navigation__content-right">
							<div className="aurora-navigation__cards">
								<div className="aurora-navigation__cards-row aurora-navigation__cards-row_down">
									<div className="aurora-navigation__card">
										<div className="aurora-navigation__card-icon aurora-navigation__card-icon_test"></div>
										<div className="aurora-navigation__card-content">
											<div className="aurora-navigation__card-title">Lorem Ipsum</div>
											<div className="aurora-navigation__card-description">Многие думают, что Lorem Ipsum - взятый с потолка псевдо-латинский набор слов</div>
										</div>
									</div>

									<div className="aurora-navigation__card">
										<div className="aurora-navigation__card-icon aurora-navigation__card-icon_test"></div>
										<div className="aurora-navigation__card-content">
											<div className="aurora-navigation__card-title">Lorem Ipsum</div>
											<div className="aurora-navigation__card-description">
												Многие думают, что Lorem Ipsum - взятый с потолка псевдо-латинский набор слов, но это не совсем так. Его корни уходят в один фрагмент классической латыни 45
											</div>
										</div>
									</div>
								</div>
								<div className="aurora-navigation__cards-row aurora-navigation__cards-row_up">
									<div className="aurora-navigation__card">
										<div className="aurora-navigation__card-icon aurora-navigation__card-icon_test"></div>
										<div className="aurora-navigation__card-content">
											<div className="aurora-navigation__card-title">Lorem Ipsum</div>
											<div className="aurora-navigation__card-description">
												Многие думают, что Lorem Ipsum - взятый с потолка псевдо-латинский набор слов, но это не совсем так. Его корни уходят в один фрагмент классической латыни 45
											</div>
										</div>
									</div>

									<div className="aurora-navigation__card">
										<div className="aurora-navigation__card-icon aurora-navigation__card-icon_test"></div>
										<div className="aurora-navigation__card-content">
											<div className="aurora-navigation__card-title">Lorem Ipsum</div>
											<div className="aurora-navigation__card-description">Многие думают, что Lorem Ipsum - взятый с потолка псевдо-латинский набор слов</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
	);
}
