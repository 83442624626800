import React from "react";
import { motion } from "framer-motion";

import NavBar from "../components/NavBar";
import HomeContainer from "../components/HomeContainer";
import CompanyJumbotron from "../components/CompanyJumbotron";
import CompanySolutions from "../components/CompanySolutions";

import CompanyServices from "../components/CompanyServices";
import PageFeedback from "../components/PageFeedback";
import PageFooter from "../components/PageFooter";

export default function Home() {
	return (
		<React.Fragment>
			<motion.div
				initial={{ opacity: 0 }}
				animate={{ opacity: 1 }}
				exit={{ opacity: 0 }}
				transition={{ ease: "easeOut", duration: 1.5 }}
			>
				<NavBar></NavBar>
				<HomeContainer></HomeContainer>
				<CompanyJumbotron></CompanyJumbotron>
				<CompanySolutions></CompanySolutions>
				<CompanyServices></CompanyServices>
				<PageFeedback></PageFeedback>
				<PageFooter></PageFooter>
			</motion.div>
		</React.Fragment>
	);
}
