import React from "react";

export default function AuroraContact() {
	return (
		<div className="aurora-contact">
			<section className="section">
				<div className="container">
					<div className="aurora-contact__content">
						<div className="aurora-contact__promo">
							<div className="aurora-contact__title">Lorem Ipsum - это текст-"рыба", часто используемый в печати</div>
							<div className="aurora-contact__description">
								Его популяризации в новое время послужили публикация листов Letraset с образцами Lorem Ipsum в 60-х годах и, в более недавнее время, программы электронной вёрстки
								типа Aldus PageMaker, в шаблонах которых используется Lorem Ipsum
							</div>
						</div>
						<div className="page-button aurora-contact__button">Получить инфо</div>
					</div>
				</div>
				<div className="aurora-contact__pattern"></div>
			</section>
		</div>
	);
}
