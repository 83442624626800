import React, { Component } from "react";

export default class index extends Component {
	constructor() {
		super();
		this.ref = React.createRef();
		this.socket = new WebSocket("ws://10.78.1.158:1234");
	}
	componentDidMount() {
		this.socket.onopen = () => {
			console.log("connected");
			this.socket.send("test");
		};
		this.socket.onmessage = event => {
			console.log(event.data);
			// let start = performance.now()
			// let image = new Image();
			// image.src = event.data;
			// image.onload = () => {
				this.ref.current.src = event.data;
			// };
            // this.ref.current.src = URL.createObjectURL(event.data);
            this.socket.send("test");

            console.log(event.data);
            
			// console.log("end", performance.now() - start);
		};
	}
	render() {
		return (
			<div>
				<img ref={this.ref} src="" alt="" />
			</div>
		);
	}
}
