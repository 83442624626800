import React from "react";
import { motion } from "framer-motion";

import NavBar from "../components/NavBar";
import AboutUs from "../components/AboutUs";

import CompanyAchievements from "../components/CompanyAchievements";
import CompanyLocation from "../components/CompanyLocation";
import PageFooter from "../components/PageFooter";

export default function Company() {
	return (
		<React.Fragment>
			<motion.div
				initial={{ opacity: 0 }}
				animate={{ opacity: 1 }}
				exit={{ opacity: 0 }}
				transition={{ ease: "easeOut", duration: 1.5 }}
			>
				<NavBar></NavBar>
				<AboutUs></AboutUs>
				<CompanyAchievements></CompanyAchievements>
				<CompanyLocation></CompanyLocation>
				<PageFooter></PageFooter>
			</motion.div>
		</React.Fragment>
	);
}
