import React, { useLayoutEffect } from "react";
import anime from "animejs";
import CortesVideo from "../CortesVideo";

export default function CortesHead() {
	const scrollElement = window.document.scrollingElement || window.document.body || window.document.documentElement;

	function getScrollPosition(tag) {
		const featuresSection = document.getElementsByClassName(tag);
		const scrolllPosition = featuresSection ? featuresSection[0].offsetTop - window.innerHeight * 0.1 : 0;
		return scrolllPosition;
	}

	function gotoDescription() {
		anime({
			targets: scrollElement,
			scrollTop: getScrollPosition("features-slider"),
			easing: "easeOutSine"
		});
	}

	function gotoDownload() {
		anime({
			targets: scrollElement,
			scrollTop: getScrollPosition("download-software"),
			easing: "easeOutSine"
		});
	}

	return (
		<div className="cortes-head">
			<div className="cortes-head__background"></div>
			<section className="section">
				<div className="container">
					<div className="cortes-head__content">
						<div className="cortes-head__content-text">
							<div className="cortes-head__title">Программный комплекс Cortes</div>
							<div className="cortes-head__description">
								Универсальное, модульное и современное решение в сфере мониторинга дорожной обстановки, фиксации событий нарушений ПДД и обеспечения безопасности дорожного движения
							</div>
						</div>
						<div className="cortes-head__actions">
							<div className="cortes-head__download" onClick={() => gotoDownload()}>
								<div className="cortes-head__download-text">Получить сборку</div>
							</div>
							<div className="cortes-head__more" onClick={() => gotoDescription()}>
								<div className="cortes-head__more-text">Узнать больше</div>
								<div className="cortes-head__more-icon"></div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<CortesVideo></CortesVideo>
		</div>
	);
}
