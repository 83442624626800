import React, { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";

export default function Pulse(props) {
	return (
		<motion.svg viewBox="0 0 50 50" className="average-speed__pulse">
			<motion.circle
				cx="25"
				cy="25"
				r="25"
				className="average-speed__pulse-circle"
				initial={{
					opacity: 0,
					scale: 0.5,
				}}
				animate={props.controls}
			></motion.circle>
			<motion.circle
				cx="25"
				cy="25"
				r="25"
				className="average-speed__pulse-circle"
				initial={{
					opacity: 0,
					scale: 0.5,
				}}
				custom={0}
				animate={props.controls}
			></motion.circle>
			<motion.circle
				cx="25"
				cy="25"
				r="25"
				className="average-speed__pulse-circle"
				initial={{
					opacity: 0,
					scale: 0.5,
				}}
				custom={1}
				animate={props.controls}
			></motion.circle>
			<motion.circle
				cx="25"
				cy="25"
				r="25"
				className="average-speed__pulse-circle"
				initial={{
					opacity: 0,
					scale: 0.5,
				}}
				custom={2}
				animate={props.controls}
			></motion.circle>
			<motion.circle
				cx="25"
				cy="25"
				r="25"
				className="average-speed__pulse-circle"
				initial={{
					opacity: 0,
					scale: 0.5,
				}}
				custom={3}
				animate={props.controls}
			></motion.circle>
		</motion.svg>
	);
}
