import React, { useState } from "react";
import FeedbackForm from "../FeedbackForm";
import { AnimatePresence } from "framer-motion";
import { withNaming } from "@bem-react/classname";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

const navBar = withNaming({ e: "__", m: "_", v: "_" })("nav-bar");

export default function NavBar() {
	const [state, setstate] = useState(false);
	const [feedbackFrom, setfeedbackFrom] = useState(false);

	return (
		<div className="nav-bar">
			<div className="container nav-bar__container">
				<div className="columns is-mobile is-gapless is-vcentered nav-bar__desktop">
					<div className="column is-2-mobile is-2-tablet is-2-desktop is-3-widescreen is-3-fullhd">
						<Link className="nav-bar__logo" to="/" onClick={() => setstate(false)}></Link>
					</div>
					<div className="column">
						<div className="nav-bar__links">
							<Link className="nav-bar__link" to="/">
								Главная
							</Link>
							<HashLink smooth={true} className="nav-bar__link" to="/#solutions">
								Решения
							</HashLink>
							<HashLink smooth={true} className="nav-bar__link" to="/#services">
								Услуги
							</HashLink>
							<Link className="nav-bar__link" to="/company">
								О компании
							</Link>
						</div>
					</div>
					<div className="column is-2-mobile is-2-tablet is-3-desktop is-3-widescreen is-3-fullhd nav-bar__contact">
						<a className="nav-bar__phone" href="tel:+79990657321">
							+7 (999) 065-73-21
						</a>
						<div className="nav-bar__feedback" onClick={() => setfeedbackFrom(true)}>
							Связаться
						</div>
					</div>
				</div>

				<div className="nav-bar__mobile">
					<div
						className={navBar("mobile-content", {
							opend: state
						})}
					>
						<div className="nav-bar__links" onClick={() => setstate(false)}>
							<Link className="nav-bar__link" to="/">
								Главная
							</Link>
							<HashLink smooth={true} className="nav-bar__link" to="/#solutions">
								Решения
							</HashLink>
							<HashLink smooth={true} className="nav-bar__link" to="/#services">
								Услуги
							</HashLink>
							<Link className="nav-bar__link" to="/company">
								О компании
							</Link>
						</div>
					</div>

					<div className="nav-bar__left">
						<div
							className={navBar("menu-icon", {
								opend: state
							})}
							onClick={() => setstate(!state)}
						>
							<div></div>
						</div>
					</div>

					<div className="nav-bar__right">
						<Link className="nav-bar__logo" to="/" onClick={() => setstate(false)}></Link>
					</div>
				</div>
			</div>
			<AnimatePresence>{feedbackFrom && <FeedbackForm close={setfeedbackFrom}></FeedbackForm>}</AnimatePresence>
		</div>
	);
}
