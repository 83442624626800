import React from "react";
import SectionHeader from "../SectionHeader";
import CompanyService from "../CompanyService/";
import range from "lodash/range";

export default function CompanyServices() {
	return (
		<div className="company-services" id="services">
			<section className="section">
				<div className="container">
					<SectionHeader title="Мы предоставляем услуги" color="black" align="center"></SectionHeader>
					<div className="company-services__products">
						<CompanyService title="Разработка аппаратных решений" type="hardware"></CompanyService>
						<CompanyService title="Разработка программного обеспечения" type="software"></CompanyService>
						<CompanyService title="Интеграция программных и аппаратных решений" type="integraion"></CompanyService>
						<CompanyService title="Сопровождение инфраструктуры" type="support"></CompanyService>
						<CompanyService title="Техническая поддержка" type="technical-support"></CompanyService>
						<CompanyService title="Поставка высокотехнологичного оборудования" type="buy"></CompanyService>
					</div>
				</div>
			</section>
		</div>
	);
}
