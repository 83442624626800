import React, { useEffect } from "react";
import { useFormik } from "formik";
import { motion, AnimatePresence } from "framer-motion";
import { observer, inject } from "mobx-react";
import { withNaming } from "@bem-react/classname";
import feedbackValidator from "../../helpers/feedbackValidator";
import Success from "./Success";

export default inject("store")(
	observer(props => {
		const formik = useFormik({
			initialValues: {
				firstName: props.store.feedbackStore.firstName,
				lastName: props.store.feedbackStore.lastName,
				number: props.store.feedbackStore.number,
				email: props.store.feedbackStore.email,
				text: props.store.feedbackStore.text
			},
			validationSchema: feedbackValidator,
			validate: props.store.feedbackStore.updateFields,
			onSubmit: props.store.feedbackStore.submitForm
		});

		return (
			<motion.div
				className="feedback-form"
				initial={{ opacity: 0 }}
				animate={{ opacity: 1 }}
				exit={{ opacity: 0 }}
				transition={{
					delay: 0.2
				}}
				layoutTransition={true}
			>
				<div className="feedback-form__background" onClick={() => props.close(false)}></div>
				<motion.div
					className="feedback-form__container"
					initial={{ opacity: 0, scale: 0.9 }}
					animate={{ opacity: 1, scale: 1 }}
					exit={{ opacity: 0, scale: 0.9 }}
					transition={{
						delay: 0.5,
						duration: 0.5
					}}
					layoutTransition={true}
				>
					<div className="feedback-form__close" onClick={() => props.close(false)}></div>
					<AnimatePresence>
						{!props.store.feedbackStore.isSended && (
							<motion.form
								className="feedback-form__form"
								onSubmit={formik.handleSubmit}
								initial={{ opacity: 0 }}
								animate={{ opacity: 1 }}
								exit={{ opacity: 0 }}
								transition={{
									duration: 0.5
								}}
							>
								<div className="feedback-form__section">
									<div className="feedback-form__column">
										<div className="feedback-form__group">
											<div className="page-input__group feedback-form__input-group">
												<div className="page-input__label feedback-form__label">Ваше имя</div>
												<div className="feedback-form__input-wrapper">
													<input
														className="page-input feedback-form__input"
														name="firstName"
														type="text"
														placeholder="Иванов Иван"
														onChange={formik.handleChange}
														value={formik.values.firstName}
													/>
													<AnimatePresence>
														{formik.errors.firstName && (
															<motion.div
																className="feedback-form__input-notification"
																initial={{ opacity: 0 }}
																animate={{ opacity: 1 }}
																exit={{ opacity: 0 }}
																transition={{
																	duration: 0.5
																}}
																title={formik.errors.firstName}
															>
																!
															</motion.div>
														)}
													</AnimatePresence>
												</div>
											</div>
										</div>
										<div className="feedback-form__group">
											<div className="page-input__group feedback-form__input-group">
												<div className="page-input__label feedback-form__label">Номер телефона</div>
												<div className="feedback-form__input-wrapper">
													<input
														className="page-input feedback-form__input"
														name="number"
														type="text"
														placeholder="+7 (999) 999-99-99"
														onChange={formik.handleChange}
														value={formik.values.number}
													/>
													<AnimatePresence>
														{formik.errors.number && (
															<motion.div
																className="feedback-form__input-notification"
																initial={{ opacity: 0 }}
																animate={{ opacity: 1 }}
																exit={{ opacity: 0 }}
																transition={{
																	duration: 0.5
																}}
																title={formik.errors.number}
															>
																!
															</motion.div>
														)}
													</AnimatePresence>
												</div>
											</div>
										</div>
									</div>
									<div className="feedback-form__column">
										<div className="page-input__group feedback-form__input-group">
											<div className="page-input__label feedback-form__label">Ваша фамилия</div>
											<div className="feedback-form__input-wrapper">
												<input
													className="page-input feedback-form__input"
													name="lastName"
													type="text"
													placeholder="Иванов Иван"
													onChange={formik.handleChange}
													value={formik.values.lastName}
												/>
												<AnimatePresence>
													{formik.errors.lastName && (
														<motion.div
															className="feedback-form__input-notification"
															initial={{ opacity: 0, scale: 0.9 }}
															animate={{ opacity: 1, scale: 1 }}
															exit={{ opacity: 0, scale: 0.9 }}
															transition={{
																duration: 0.5
															}}
															title={formik.errors.lastName}
														>
															!
														</motion.div>
													)}
												</AnimatePresence>
											</div>
										</div>
										<div className="page-input__group feedback-form__input-group">
											<div className="page-input__label feedback-form__label">Email адрес</div>
											<div className="feedback-form__input-wrapper">
												<input
													className="page-input feedback-form__input"
													name="email"
													type="text"
													placeholder="example@example.com"
													onChange={formik.handleChange}
													value={formik.values.email}
												/>
												<AnimatePresence>
													{formik.errors.email && (
														<motion.div
															className="feedback-form__input-notification"
															initial={{ opacity: 0 }}
															animate={{ opacity: 1 }}
															exit={{ opacity: 0 }}
															transition={{
																duration: 0.5
															}}
															title={formik.errors.email}
														>
															!
														</motion.div>
													)}
												</AnimatePresence>
											</div>
										</div>
									</div>
								</div>
								<div className="feedback-form__section">
									<div className="feedback-form__group feedback-form__input-group_text">
										<div className="page-input__group feedback-form__input-group_text">
											<div className="page-input__label feedback-form__label">Текст обращения</div>
											<textarea
												className="page-input feedback-form__input feedback-form__input_text"
												name="text"
												placeholder="Текст сообщения"
												onChange={formik.handleChange}
												value={formik.values.text}
											></textarea>
										</div>
									</div>
								</div>
								<div className="feedback-form__button-wrapper">
									<div className={`feedback-form__button-loader ${!props.store.feedbackStore.isSending && "feedback-form__button-loader_disabled"}`}></div>
									<button className={`page-button feedback-form__button ${props.store.feedbackStore.isSending && "feedback-form__button_hidden"}`} type="submit">
										Отправить сообщение
									</button>
								</div>
							</motion.form>
						)}

						{props.store.feedbackStore.isSended && <Success></Success>}
					</AnimatePresence>
				</motion.div>
			</motion.div>
		);
	})
);
