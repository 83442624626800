import React, { Suspense, useRef, useEffect } from "react";
import { Canvas, useFrame, useThree, extend } from "react-three-fiber";
import { useLoader } from "react-three-fiber";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";

extend({ OrbitControls });

function Asset({ url }) {
	const gltf = useLoader(GLTFLoader, url);
	return <primitive object={gltf.scene} />;
}

function Test(props) {
	const controls = useRef();
	const { camera } = useThree();

    useFrame(state => controls.current.update())
    useFrame(({ gl, scene, camera }) => gl.render(scene, camera), 1)

	return <orbitControls ref={controls} args={[camera]} {...props} />;
}

export default function Model() {
	return (
		<Canvas style={{ background: "lightblue" }}>
			<Test></Test>
			<ambientLight intensity={0.5} />
			<mesh>
				<boxBufferGeometry attach="geometry" args={[1, 1, 1]} />
				<meshNormalMaterial attach="material" />
			</mesh>
			{/* <Suspense fallback={<mesh></mesh>}>
				<Asset url="aurora.glb" />
			</Suspense> */}
		</Canvas>
	);
}
