import React from "react";
import { motion, useInvertedScale } from "framer-motion";
import range from "lodash/range";

export default function SpecificationsItems() {
	let inverse = useInvertedScale();
	return (
		<motion.div className="device-specifications__general" style={{ ...inverse }}>
			{range(6).map(() => (
				<div className="device-specifications__item">
					<div className="device-specifications__item-specification">13.37</div>
					<div className="device-specifications__item-title">Есть много вариантов</div>
					<div className="device-specifications__item-subtitle">
						Если вам нужен Lorem Ipsum для серьёзного проекта
					</div>
				</div>
			))}
		</motion.div>
	);
}
