import React, { useRef, useLayoutEffect, useState } from "react";
import { motion, useTransform, useViewportScroll } from "framer-motion";

export default function DeviceAutofocus() {
	const [elementPosition, setElementPosition] = useState({
		top: null,
		bottom: null
	});
	const ref = useRef(null);
	let progress = useViewportScroll();
	let transformed = useTransform(progress.scrollY, [elementPosition.top, elementPosition.bottom], [0, 100]);
	let blockScale = useTransform(transformed, [30, 80], [1.2, 1]);
	let blockBorderInner = useTransform(transformed, [30, 80], [0, 15]);
	let blockBorderOuter = useTransform(transformed, [0, 30, 80], [5, 5, 15]);

	useLayoutEffect(() => {
		setElementPosition({
			top: ref.current.offsetTop - window.innerHeight,
			bottom: ref.current.offsetTop + ref.current.clientHeight - window.innerHeight
		});
	}, [ref]);

	return (
		<div ref={ref} className="device-autofocus">
			<section className="section">
				<div className="container">
					<div className="device-autofocus__content">
						<div className="device-autofocus__shot">
							<motion.div
								style={{
									scale: blockScale,
									borderTopRightRadius: blockBorderInner,
									borderBottomRightRadius: blockBorderInner,
									borderTopLeftRadius: blockBorderOuter,
									borderBottomLeftRadius: blockBorderOuter
								}}
								className="device-autofocus__shot-image"
							></motion.div>
							<div className="device-autofocus__shot-content">
								<div className="device-autofocus__shot-info">
									<div className="device-autofocus__shot-info-icon device-autofocus__shot-info-icon_sun"></div>
									<div className="device-autofocus__shot-info-title">Съемка днем</div>
								</div>
								<div className="device-autofocus__shot-description">
									<div className="device-autofocus__shot-text">Давно выяснено, что при оценке дизайна и композиции читаемый текст мешает сосредоточиться</div>
									<div className="device-autofocus__shot-text">Давно выяснено, что при оценке дизайна и композиции читаемый текст мешает сосредоточиться</div>
									<div className="device-autofocus__shot-text">Давно выяснено, что при оценке дизайна и композиции читаемый текст мешает сосредоточиться</div>
									<div className="device-autofocus__shot-text">Давно выяснено, что при оценке дизайна и композиции читаемый текст мешает сосредоточиться</div>
								</div>
							</div>
						</div>

						<div className="device-autofocus__shot">
							<motion.div
								style={{
									scale: blockScale,
									borderTopRightRadius: blockBorderOuter,
									borderBottomRightRadius: blockBorderOuter,
									borderTopLeftRadius: blockBorderInner,
									borderBottomLeftRadius: blockBorderInner
								}}
								className="device-autofocus__shot-image"
							></motion.div>
							<div className="device-autofocus__shot-content">
								<div className="device-autofocus__shot-info">
									<div className="device-autofocus__shot-info-icon device-autofocus__shot-info-icon_moon"></div>
									<div className="device-autofocus__shot-info-title">Съемка ночью</div>
								</div>
								<div className="device-autofocus__shot-description">
									<div className="device-autofocus__shot-text">Давно выяснено, что при оценке дизайна и композиции читаемый текст мешает сосредоточиться</div>
									<div className="device-autofocus__shot-text">Давно выяснено, что при оценке дизайна и композиции читаемый текст мешает сосредоточиться</div>
									<div className="device-autofocus__shot-text">Давно выяснено, что при оценке дизайна и композиции читаемый текст мешает сосредоточиться</div>
									<div className="device-autofocus__shot-text">Давно выяснено, что при оценке дизайна и композиции читаемый текст мешает сосредоточиться</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
	);
}
