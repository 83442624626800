import React from "react";
import PropTypes from "prop-types";

import { withNaming } from "@bem-react/classname";

const companyIcon = withNaming({ e: "__", m: "_", v: "_" })("company-achievement");

function CompanyAchievement(props) {
	return (
		<div className="company-achievement">
			<div className={companyIcon("icon", { [props.achievement]: true })}></div>

			<div className="company-achievement__info">
				<div className="company-achievement__number">{props.number}</div>
				<div className="company-achievement__title">{props.title}</div>
			</div>
		</div>
	);
}

CompanyAchievement.propTypes = {
	achievement: PropTypes.string.isRequired,
	number: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
};

export default CompanyAchievement;
