import React from "react";
import CompanyAchievement from "../CompanyAchievement";

export default function index() {
	return (
		<div className="company-achievements">
			<section className="section">
				<div className="container">
					<div className="company-achievements__content">
						<CompanyAchievement achievement="hexagon" number="6" title="Реализованных проектов"></CompanyAchievement>
						<CompanyAchievement achievement="oval" number="3" title="Сертифицированных решения"></CompanyAchievement>
						<CompanyAchievement achievement="triangle" number="5" title="Направлений деятельности"></CompanyAchievement>
						<CompanyAchievement achievement="circle" number="14" title="Профессионалов в штате"></CompanyAchievement>
					</div>
				</div>
			</section>
		</div>
	);
}
