import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { useInView } from "react-intersection-observer";
import { motion, useAnimation } from "framer-motion";
import { withNaming } from "@bem-react/classname";
import Line from "./Line";
import Pulse from "./Pulse";
import SectionHeader from "../SectionHeader";

const contentAnimation = {
	visible: {
		borderRadius: 0
	},
	hidden: {
		borderRadius: 5
	}
};

const carAnimation = {
	start: {
		left: -300
	},
	stop: {
		left: "100%"
	}
};

const pulseAnimation = position =>
	position !== undefined
		? {
				opacity: [0.5, 0],
				scale: 2,
				transition: {
					times: [0.1, 0.9],
					delay: position * 0.3,
					duration: 1.2
				}
		  }
		: {
				opacity: [1, 0],
				transition: {
					times: [0.2, 0.8],
					duration: 1.2
				}
		  };

const shadowAnimation = {
	hidden: {
		background: "linear-gradient(0, rgba(0, 0, 0, 0) 0%, transparent)"
	},
	visible: {
		background: "linear-gradient(0, rgba(0, 0, 0, 0.1) 25%, transparent)"
	}
};

const featureAnimation = position => ({
	opacity: 1,
	y: 0,
	transition: {
		delay: position * 0.2,
		duration: 0.4
	}
});

// const lineAnimation = {
//     strokeDashoffset: 2000,
//     transition
// }

const averageSpeed = withNaming({ e: "__", m: "_", v: "_" })("average-speed");

export default function AverageSpeed() {
	const pulsesStatus = useRef({
		first: false,
		second: false
	});
	const containerRef = useRef(null);
	const containerWidth = useRef(Infinity);
	const [animationStarted, setanimationStarted] = useState(false);
	const [hidden, setHide] = useState(false);
	const [ref, inView, entry] = useInView();

	const firstPulse = useAnimation();
	const secondPulse = useAnimation();
	const featureControl = useAnimation();

	useEffect(() => {
		setTimeout(() => {
			setanimationStarted(true);
		}, 3000);
	}, []);

	useLayoutEffect(() => {
		containerWidth.current = containerRef.current.clientWidth;
	}, []);

	useEffect(() => {
		let carElement = document.getElementById("car");
		let observer = new MutationObserver(mutationsList => {
			let transfromX = new DOMMatrix(window.getComputedStyle(carElement).getPropertyValue("transform")).e;
			console.log(transfromX, containerWidth);
			if (transfromX >= -300 && transfromX <= -250 && !pulsesStatus.current.first) {
				startPulse(1);
				pulsesStatus.current = { ...pulsesStatus.current, first: true };
			}

			if (transfromX > containerWidth.current - 400 && !pulsesStatus.current.second) {
				startPulse(2);
				pulsesStatus.current = { ...pulsesStatus.current, second: true };
			}
		});
		observer.observe(carElement, {
			attributes: true
		});
		return () => observer.disconnect();
	}, []);

	function startPulse(pulse) {
		pulse === 1 ? firstPulse.start(pulseAnimation) : secondPulse.start(pulseAnimation);
	}

	function setAnimationComplete() {
		setHide(true);
		featureControl.start(featureAnimation);
	}

	return (
		<div className="average-speed" ref={ref}>
			<section className="section">
				<div className="container">
					<SectionHeader title="Измерение средней скорости движения на протяженном участке дороги" color="black" align="left"></SectionHeader>
					<motion.div
						className={averageSpeed("container", {
							hidden
						})}
						layoutTransition={{ type: "tween" }}
					>
						<motion.div
							className={averageSpeed("content", {
								hidden
							})}
							initial={"visible"}
							animate={hidden ? "hidden" : "visible"}
							variants={contentAnimation}
							layoutTransition={{ type: "tween" }}
						>
							<div ref={containerRef} className="average-speed__background">
								<motion.div
									className="average-speed__background-shadow"
									initial={"hidden"}
									animate={hidden ? "visible" : " hidden"}
									variants={shadowAnimation}
									transition={{
										type: "spring",
										duration: 5
									}}
								></motion.div>

								<div className="average-speed__features">
									<motion.div
										className="average-speed__feature"
										initial={{
											opacity: 0,
											y: "100%"
										}}
										custom={1}
										animate={featureControl}
									>
										<div className="average-speed__feature-title">1412</div>
										<div className="average-speed__feature-description">Давно выяснено, что при оценке дизайна и композиции</div>
									</motion.div>
									<motion.div
										className="average-speed__feature"
										initial={{
											opacity: 0,
											y: "100%"
										}}
										custom={2}
										animate={featureControl}
									>
										<div className="average-speed__feature-title">1412</div>
										<div className="average-speed__feature-description">Давно выяснено, что при оценке дизайна и композиции</div>
									</motion.div>
									<motion.div
										className="average-speed__feature"
										initial={{
											opacity: 0,
											y: "100%"
										}}
										custom={3}
										animate={featureControl}
									>
										<div className="average-speed__feature-title">1412</div>
										<div className="average-speed__feature-description">Давно выяснено, что при оценке дизайна и композиции</div>
									</motion.div>
								</div>

								<div className="average-speed__background-line">
									<Line />
								</div>
								<div
									className={averageSpeed("background-elements", {
										hidden
									})}
								>
									<motion.div
										id="car"
										className="average-speed__background-car"
										initial={"start"}
										animate={animationStarted ? "stop" : "start"}
										transition={{ duration: 5 }}
										variants={{
											start: { x: "-200%" },
											stop: {
												x: containerWidth.current - 100
											}
										}}
										onAnimationComplete={setAnimationComplete}
									></motion.div>
									<div className="average-speed__background-pulse">
										<Pulse controls={firstPulse}></Pulse>
									</div>
									<div className="average-speed__background-pulse">
										<Pulse controls={secondPulse}></Pulse>
									</div>
								</div>
								<div className="average-speed__background-line">
									<Line />
								</div>
							</div>
						</motion.div>
					</motion.div>
				</div>
			</section>
		</div>
	);
}
