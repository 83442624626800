import React from "react";
import CompanySolution from "../CompanySolution";

export default function CompanySolutions() {
	return (
		<div className="company-solutions" id="solutions">
			<section className="section">
				<div className="container">
					<div className="company-solutions__title">Наши направления работы</div>
					<div className="company-solutions__products">
						<CompanySolution
							title="Видеодетектор «ОКО»"
							description="
                            Устройство предназначено для распознавания государственных регистрационных номеров и определения типа или марки транспортного средства
                            "
							product="oko"
						></CompanySolution>
						<CompanySolution
							title="Система измерительная «Аврора»"
							description="Производит автоматическое распознавание государственных регистрационных номеров и фиксацию нарушений ПДД"
                            product="aurora"
                            link="/aurora"
						></CompanySolution>
						<CompanySolution
							title="Программный комплекс Cortes"
							description="Платформонезависимый комплекс для обеспечения видеоаналитики дорожной обстановки"
                            product="cortes"
                            link="/cortes"
						></CompanySolution>
					</div>
				</div>
			</section>
		</div>
	);
}
