import React, { Component } from "react";
import { motion } from "framer-motion";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
import SectionHeader from "../SectionHeader";
import Slider from "react-slick";

const icon = {
	hidden: {
		opacity: 0,
		pathLength: 0,
		stroke: "rgba(0, 0, 0, 0)"
	},
	visible: {
		opacity: 1,
		pathLength: 1,
		stroke: "rgba(0, 0, 0, 1)"
	}
};

const settings = {
	dots: false,
	arrows: false,
	infinite: true,
	speed: 500,
	slidesToShow: 1,
	slidesToScroll: 1
};

export default class SoftwareSlider extends Component {
	constructor(props) {
		super(props);
		this.reactSlick = React.createRef();
	}
	componentDidMount() {
		// setTimeout(() => {
		// 	this.reactSlick.current.slickGoTo(2);
		// }, 4000);
	}
	render() {
		return (
			<div className="software-slider">
				<section className="section">
					<div className="container">
						<SectionHeader title="Есть много вариантов Lorem Ipsum, но большинство из них имеет не всегда приемлемые модификации" color="black" align="center"></SectionHeader>
						<div className="software-slider__content">
							<div className="software-slider__slider">
								<div className="software-slider__slider-sections-container">
									<div className="software-slider__slider-sections">
										<div className="software-slider__slider-section">Мониторинг</div>
										<div className="software-slider__slider-section">Фиксация</div>
										<div className="software-slider__slider-section">Обновление</div>
										<div className="software-slider__slider-section">Анализ</div>
									</div>
								</div>
								<Slider ref={this.reactSlick} {...settings}>
									<div className="software-slider__slider-content">
										<div className="software-slider__slider-image"></div>

										<div className="software-slider__info">
											<div className="software-slider__info-header software-slider__info-header_mini">Мониторинг</div>
											<div className="software-slider__info-header">Lorem Ipsum - это текст-"рыба"</div>
											<div className="software-slider__info-text">
												Есть много вариантов Lorem Ipsum, но большинство из них имеет не всегда приемлемые модификации, например, юмористические вставки или слова, которые даже
												отдалённо не напоминают латынь
											</div>
											<div className="software-slider__checkitems">
												<div className="software-slider__checkitem">
													<div className="software-slider__checkitem-box">
														<div className="software-slider__checkitem-box-icon-container">
															<motion.svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" className="software-slider__checkitem-box-icon">
																<motion.g transform="matrix(1.12521,0,0,1.12521,-0.261976,0.861936)">
																	<motion.path
																		d="M0.855,9.329L8.087,16.076L19.162,1.944"
																		variants={icon}
																		initial="hidden"
																		animate="visible"
																		transition={{
																			default: { duration: 2, ease: "easeInOut" }
																		}}
																	/>
																</motion.g>
															</motion.svg>
														</div>
													</div>
													<div className="software-slider__checkitem-text">Классический текст</div>
												</div>
											</div>
										</div>
									</div>
								</Slider>
							</div>
						</div>
					</div>
				</section>
			</div>
		);
	}
}
