import React from "react";
import SectionHeader from "../SectionHeader";

import range from "lodash/range";

export default function KeyFeatures() {
	return (
		<div className="key-features">
			<section className="section">
				<div className="container">
					<SectionHeader
						title="Создан для ваших задач"
						description="Прозрачно интегрируйте программный комплекс Cortes в Вашу существующую инфраструктуру"
						color="white"
						align="center"
						size="large"
					></SectionHeader>
					<div className="key-features__content">
						<div className="key-features__item">
							<div className="key-features__item-icon key-features__item-icon_monitor"></div>
							<div className="key-features__item-description">Самодиагностика и мониторинг параметров работы</div>
						</div>

						<div className="key-features__item">
							<div className="key-features__item-icon key-features__item-icon_folder"></div>
							<div className="key-features__item-description">Хранение информации о зафиксированных событиях</div>
						</div>

						<div className="key-features__item">
							<div className="key-features__item-icon key-features__item-icon_hash"></div>
							<div className="key-features__item-description">Электронно-цифровая подпись результатов работы</div>
						</div>

						<div className="key-features__item">
							<div className="key-features__item-icon key-features__item-icon_api"></div>
							<div className="key-features__item-description">Простой и понятный API</div>
						</div>

						<div className="key-features__item">
							<div className="key-features__item-icon key-features__item-icon_website"></div>
							<div className="key-features__item-description">Современный интуитивно понятный интерфейс пользователя</div>
						</div>
						<div className="key-features__item">
							<div className="key-features__item-icon key-features__item-icon_analysis"></div>
							<div className="key-features__item-description">Сбор статистической информации о дорожной ситуации</div>
						</div>
					</div>
				</div>
			</section>
		</div>
	);
}
