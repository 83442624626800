import React from "react";

export default function index() {
	return (
		<div className="about-us">
			<section className="section">
				<div className="container">
					<div className="about-us__content">
						<div className="about-us__title">Немного о нас</div>
						<div className="about-us__description">Мы создаем интеллектуальные технологии, которые помогут вам справиться даже с самыми сложными задачами</div>
					</div>
				</div>
			</section>
		</div>
	);
}
