import React from "react";

export default function PageFeedback() {
	return (
		<div className="page-feedback">
			<section className="section">
				<div className="container">
					<div className="page-feedback__title">Задайте нам вопрос по совместному сотрудничеству</div>
					<div className="page-feedback__description">Оставьте номер телефона и наш специалист свяжется с Вами в ближайшее время</div>

					<div className="page-feedback__inputs">
						<div className="page-feedback__inputs-group">
							<input className="page-feedback__input" type="text" name="" placeholder="Иванов Иван" />
							<input className="page-feedback__input" type="text" name="" placeholder="+7 (999) 999-99-99" />
							<input className="page-feedback__input" type="text" name="" placeholder="example@example.com" />
						</div>
						<div className="page-feedback__inputs-group page-feedback__inputs-group_text">
							<textarea className="page-feedback__input page-feedback__input_text" placeholder="Текст сообщения"></textarea>
							<div className="page-feedback__button">Отправить</div>
						</div>
					</div>
				</div>
			</section>
		</div>
	);
}
