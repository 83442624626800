import React from "react";
import { motion } from "framer-motion";

import NavBar from "../components/NavBar";
import ViewModel from "../components/ViewModel";
import DeviceSpecifications from "../components/DeviceSpecifications";
import PageFooter from "../components/PageFooter";
import DeviceCamera from "../components/DeviceCamera";
import DeviceAutofocus from "../components/DeviceAutofocus";
import AuroraHeader from "../components/AuroraHeader/";
import AverageSpeed from "../components/AverageSpeed";
import TimeAccuracy from "../components/TimeAccuracy";
import DeviceDescription from "../components/DeviceDescription";
import AvailableViolations from "../components/AvailableViolations";
import AuroraNavigation from "../components/AuroraNavigation";
import AuroraContact from "../components/AuroraContact";

export default function Aurora() {
	return (
		<React.Fragment>
			<motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ ease: "easeOut", duration: 1.5 }}>
				<NavBar></NavBar>
				<AuroraHeader></AuroraHeader>
				<AuroraNavigation></AuroraNavigation>
				<DeviceDescription></DeviceDescription>
				<AvailableViolations></AvailableViolations>
				<TimeAccuracy></TimeAccuracy>
				<AverageSpeed></AverageSpeed>
				<DeviceCamera></DeviceCamera>
				<DeviceAutofocus></DeviceAutofocus>
				<DeviceSpecifications></DeviceSpecifications>
				<ViewModel></ViewModel>
				<AuroraContact></AuroraContact>
				<PageFooter></PageFooter>
			</motion.div>
		</React.Fragment>
	);
}
