import React from "react";
import { Link } from "react-router-dom";

export default function PageFooter() {
	return (
		<div className="page-footer">
			<div className="container">
				<div className="page-footer__part">
					<div className="page-footer__logo"></div>
					<div className="page-footer__links">
						<Link className="page-footer__link" to="/">
							Главная
						</Link>
						<Link className="page-footer__link" to="/">
							Решения
						</Link>
						<Link className="page-footer__link" to="/">
							Услуги
						</Link>
						<Link className="page-footer__link" to="/">
							О компании
						</Link>
						<Link className="page-footer__link" to="/">
							Контакты
						</Link>
					</div>
				</div>

				<div className="page-footer__separator"></div>
				<div className="page-footer__part">
					<div className="page-footer__info">© 2019 ООО «Новые интеллектуальные электронные системы»</div>
					<a className="page-footer__info page-footer__info_phone" href="tel:+79990657321">
						+7 (999) 065-73-21
					</a>
				</div>
			</div>
		</div>
	);
}
