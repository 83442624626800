import React, { Component } from "react";
import SectionHeader from "../SectionHeader";
import Swiper from "react-id-swiper";
import _ from "lodash";
import "swiper/css/swiper.css";

const settings = {
	dots: false,
	arrows: false,
	infinite: false,
	speed: 500,
	slidesToShow: 2,
	slidesToScroll: 1,
	autoplay: true
};

export default class FeaturesSlider extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<div className="features-slider">
				<section className="section">
					<div className="container">
						<SectionHeader title="Широкие возможности для мониторинга и контроля ситуации на дороге" color="white" align="center"></SectionHeader>
						<div className="features-slider__content">
							<div className="features-slider__items">
								<Swiper slidesPerView="auto" freeMode={true}>
									<div>
										<div className="features-slider__item">
											<div className="features-slider__item-icon features-slider__item-icon_monitoring"></div>
											<div className="features-slider__item-title">Продвинутый мониторинг</div>
											<div className="features-slider__item-description">Формирование, хранение и отображение более 88 составных признаков</div>
										</div>
									</div>

									<div>
										<div className="features-slider__item">
											<div className="features-slider__item-icon features-slider__item-icon_security"></div>
											<div className="features-slider__item-title">Повышенная надежность</div>
											<div className="features-slider__item-description">Поддержка ОС семейства Gnu/Linux и аппаратных средств обеспечения отказоустойчивости</div>
										</div>
									</div>

									<div>
										<div className="features-slider__item">
											<div className="features-slider__item-icon features-slider__item-icon_"></div>
											<div className="features-slider__item-title">Фиксация различных типов нарушений</div>
											<div className="features-slider__item-description">Cortes способен фиксировать 16 основных и до 10 дополнительных типов нарушений</div>
										</div>
									</div>

									<div>
										<div className="features-slider__item">
											<div className="features-slider__item-icon features-slider__item-icon_quality"></div>
											<div className="features-slider__item-title">Превосходная картинка</div>
											<div className="features-slider__item-description">
												Поддержка фотосенсоров ведущих производителей промышленных камер и высококачественных цифровых камер машинного зрения
											</div>
										</div>
									</div>

									<div>
										<div className="features-slider__item">
											<div className="features-slider__item-icon features-slider__item-icon_photo-camera"></div>
											<div className="features-slider__item-title">Качественные ночные снимки</div>
											<div className="features-slider__item-description">Алгоритмы постобработки фото позволяют достичь наилучшего качество изображения</div>
										</div>
									</div>
								</Swiper>
							</div>
						</div>
					</div>
				</section>
			</div>
		);
	}
}
