import React from "react";
import { motion } from "framer-motion";

import NavBar from "../components/NavBar";
import CortesHead from "../components/CortesHead";
import KeyFeatures from "../components/KeyFeatures";
import SoftwareInfo from "../components/SoftwareInfo";
import DownloadSoftware from "../components/DownloadSoftware";
import SoftwareSlider from "../components/SoftwareSlider";
import PageFooter from "../components/PageFooter";
import WeUse from "../components/WeUse";
import FeaturesSlider from "../components/FeaturesSlider";
import CortesVideo from "../components/CortesVideo";

export default function Cortes() {
	return (
		<React.Fragment>
			<motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ ease: "easeOut", duration: 1.5 }}>
				<NavBar></NavBar>
				<CortesHead></CortesHead>
				<FeaturesSlider></FeaturesSlider>
				{/* <SoftwareSlider></SoftwareSlider> */}
				<KeyFeatures></KeyFeatures>
				<WeUse></WeUse>
				<DownloadSoftware></DownloadSoftware>
				<SoftwareInfo></SoftwareInfo>
				<PageFooter></PageFooter>
			</motion.div>
		</React.Fragment>
	);
}
