import React from "react";
import anime from "animejs";

export default function HomeContainer() {
	const scrollElement = window.document.scrollingElement || window.document.body || window.document.documentElement;

	function gotoDescription() {
		anime({
			targets: scrollElement,
			scrollTop: window.innerHeight * 0.65,
			easing: "easeOutSine"
		});
	}

	return (
		<div className="home-container">
			<section className="section">
				<div className="container home-container__container">
					<div className="home-container__promo">
						<div className="home-container__slogan">
							<div className="home-container__slogan-phrase">Разрабатываем комплексы</div>
							<div className="home-container__slogan-phrase">фотовидеофиксации</div>
							<div className="home-container__slogan-phrase">нарушений ПДД</div>
						</div>
						<div className="home-container__description">Мы создаем интеллектуальные технологии, которые помогут вам справиться даже с самыми сложными задачами</div>

						<div className="home-container__contact">
							<div className="home-container__feedback">
								<div className="home-container__feedback-text">Связаться с нами</div>
								<div className="home-container__feedback-icon">
									<div className="home-container__feedback-icon-content"></div>
								</div>
							</div>
							<div className="home-container__find-more" onClick={() => gotoDescription()}>
								Узнать подробнее
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
	);
}
