import React from "react";
import PropTypes from "prop-types";

export default function ItemDocument(props) {
	return (
		<div className={`item-document item-document_${props.type}`}>
			<div className="item-document__header">{props.title}</div>
			<div className="item-document__subtitle">{props.subtitle}</div>
			<div className="item-document__tags">
				{props.files.map((file) => (
					<a className="item-document__tag" href={file.link} target="_blank">
						<div className="item-document__tag-icon"></div>
						<div className="item-document__tag-name"> {file.tag}</div>
					</a>
				))}
			</div>
		</div>
	);
}

ItemDocument.propTypes = {
	link: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	subtitle: PropTypes.string.isRequired,
	tag: PropTypes.string.isRequired,
	files: PropTypes.array,
};
