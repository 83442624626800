import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";

const loaderAnimation = {
	visible: {
		opacity: 1
	},
	hidden: {
		opacity: 0
	}
};

export default function CortesVideo() {
	const [state, setstate] = useState(false);

	return (
		<div className="cortes-video">
			<section className="section">
				<div className="container">
					<div className={`cortes-video__container ${state && "cortes-video__container_loaded"}`}>
						<AnimatePresence>
							{!state && <motion.div className="cortes-video__loader" initial={"visible"} animate={"visible"} exit={"hidden"} variants={loaderAnimation}></motion.div>}
						</AnimatePresence>
						<video playsInline muted loop autoPlay preload="metadata" onLoadedData={() => setstate(true)}>
							<source src="cortes-show.mp4" type="video/mp4" />
						</video>
					</div>
				</div>
			</section>
		</div>
	);
}
