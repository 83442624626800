import React from "react";

export default function CompanyJumbotron() {
	return (
		<div className="company-jumbotron">
			<section className="section">
				<div className="container">
					<div className="company-jumbotron__container">
						<div className="company-jumbotron__content">
							<div className="company-jumbotron__logo"></div>
							<div className="company-jumbotron__description">
								<div className="company-jumbotron__description-title">Новые интеллектуальные электронные системы</div>
								<div className="company-jumbotron__description-text">
									Мы разрабатываем современные, высокотехнологичные программные и аппаратные решения для различных сфер бизнеса
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
	);
}
