import React, { Component } from "react";
import SectionHeader from "../SectionHeader";
import SpecificationsItems from "./SpecificationsItems";
import { withNaming } from "@bem-react/classname";
import { motion } from "framer-motion";

const deviceSpecifications = withNaming({ e: "__", m: "_", v: "_" })("device-specifications");

export default class Device extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isOpen: false
		};
		this.openFull = this.openFull.bind(this);
	}
	openFull() {
		this.setState({
			isOpen: !this.state.isOpen
		});
	}
	render() {
		return (
			<div className="device-specifications">
				<section className="section">
					<div className="container">
						<SectionHeader title="Есть много вариантов Lorem Ipsum, но большинство из них имеет не всегда приемлемые модификации" color="black" align="center"></SectionHeader>

						<motion.div className="device-specifications__content" layoutTransition={true}>
							<SpecificationsItems></SpecificationsItems>

							<motion.div
								className={deviceSpecifications("more", {
									active: this.state.isOpen
								})}
								layoutTransition={true}
								onClick={this.openFull}
							>
								<div
									className={deviceSpecifications("more-button", {
										hidden: this.state.isOpen
									})}
								>
									<div className="device-specifications__more-button-icon"></div>
									<div className="device-specifications__more-button-text">Полные характеристики</div>
								</div>

								<div
									className={deviceSpecifications("more-text", {
										active: this.state.isOpen
									})}
								>
									Давно выяснено, что при оценке дизайна и композиции читаемый текст мешает сосредоточиться. Lorem Ipsum используют потому, что тот обеспечивает более или менее
									стандартное заполнение шаблона, а также реальное распределение букв и пробелов в абзацах, которое не получается при простой дубликации "Здесь ваш текст.. Здесь
									ваш текст.. Здесь ваш текст.." Многие программы электронной вёрстки и редакторы HTML используют Lorem Ipsum в качестве текста по умолчанию, так что поиск по
									ключевым словам "lorem ipsum" сразу показывает, как много веб-страниц всё ещё дожидаются своего настоящего рождения. За прошедшие годы текст Lorem Ipsum получил
									много версий. Некоторые версии появились по ошибке, некоторые - намеренно (например, юмористические варианты).
								</div>
							</motion.div>
						</motion.div>
					</div>
				</section>
			</div>
		);
	}
}
