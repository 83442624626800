import * as Yup from "yup";

const feedbackValidator = Yup.object().shape({
	firstName: Yup.string()
		.min(2, "Слишком короткое имя")
		.max(50, "Слишком длинное имя")
		.required("Обязательное поле"),
	lastName: Yup.string()
		.min(2, "Слишком короткая фамилия")
		.max(50, "Слишком длинная фамилия")
		.required("Обязательное поле"),
	number: Yup.string()
		// .matches(/(\+7|8)[- _]*\(?[- _]*(\d{3}[- _]*\)?([- _]*\d){7}|\d\d[- _]*\d\d[- _]*\)?([- _]*\d){6})/g)
		.required("Обязательное поле"),
	email: Yup.string()
		.email("Неверный email")
		.required("Обязательное поле"),
	text: Yup.string().min(2, "Слишком короткий текст")
});

export default feedbackValidator;
