import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { withNaming } from "@bem-react/classname";

const companyIcon = withNaming({ e: "__", m: "_", v: "_" })("company-solution");

function CompanySolution(props) {
	return (
		<div className="company-solution">
			<div className="company-solution__info">
				<div className="company-solution__info-title">{props.title} </div>
				<div className="company-solution__info-descritpion"> {props.description} </div>
			</div>

			<div className="company-solution__more">
				<Link to={props.link} className="company-solution__button">
					<div className="company-solution__button-text">Подробнее</div>
					<div className="company-solution__button-icon"></div>
				</Link>
				<div className={companyIcon("icon", { [props.product]: true })}></div>
			</div>
		</div>
	);
}

CompanySolution.propTypes = {
	title: PropTypes.string.isRequired,
	description: PropTypes.string.isRequired,
	product: PropTypes.string.isRequired,
	link: PropTypes.string.isRequired
};

export default CompanySolution;
