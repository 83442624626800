import React from "react";
import SectionHeader from "../SectionHeader";
import range from "lodash/range";

export default function WeUse() {
	return (
		<div className="we-use">
			<section className="section we-use__content-section">
				<div className="container">
					<SectionHeader
						title="Разработано с использованием передовых технологий"
						description="Использование передовых продуктов позволило нам создать программное обеспечение нового поколения для профессионального мониторинга дорожной обстановки"
						color="black"
						align="center"
					></SectionHeader>
				</div>
			</section>

			<div className="we-use__content">
				{range(3).map(() => (
					<div className="we-use__section">
						<div className="we-use__item">
							<div className="we-use__item-logo we-use__item-logo_arm"></div>
						</div>
						<div className="we-use__item">
							<div className="we-use__item-logo we-use__item-logo_basler"></div>
						</div>
						<div className="we-use__item">
							<div className="we-use__item-logo we-use__item-logo_dalsa"></div>
						</div>
						<div className="we-use__item">
							<div className="we-use__item-logo we-use__item-logo_nginx"></div>
						</div>
						<div className="we-use__item">
							<div className="we-use__item-logo we-use__item-logo_postgres"></div>
						</div>
						<div className="we-use__item">
							<div className="we-use__item-logo we-use__item-logo_opensuse"></div>
						</div>
						<div className="we-use__item">
							<div className="we-use__item-logo we-use__item-logo_hikvision"></div>
						</div>
						<div className="we-use__item">
							<div className="we-use__item-logo we-use__item-logo_intel"></div>
						</div>
						<div className="we-use__item">
							<div className="we-use__item-logo we-use__item-logo_kubernetes"></div>
						</div>
					</div>
				))}
			</div>
		</div>
	);
}
