import { types } from "mobx-state-tree";
import feedbackModel from "../models/feedback";

const feedbackStore = types
	.model("FeedbackStore", {
		isSended: false,
		isSending: false,
		firstName: "",
		lastName: "",
		number: "",
		email: "",
		text: ""
	})
	.views(self => ({
		get fields() {
			return self;
		}
	}))
	.actions(self => ({
		updateFields(values) {
			for (const key in values) {
				self[key] = values[key];
			}
		},
		async submitForm(values) {
			// alert(JSON.stringify(values, null, 2));

			self.changeFormLoading(true);
			setTimeout(() => {
				self.changeFormState(true);
			}, 2500);
			setTimeout(() => {
				self.changeFormState(false);
			}, 4000);
		},
		changeFormState(state) {
			self.isSended = state;
		},
		changeFormLoading(state) {
			self.isSending = state;
		}
	}));

export default feedbackStore;
