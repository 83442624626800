import React from "react";

export default function Card() {
	return (
		<div className="available-violations__card">
			<div className="available-violations__card-ident">
				<div className="available-violations__card-ident-type">C</div>
				<div className="available-violations__card-ident-number">1.1</div>
			</div>
			<div className="available-violations__card-type">Есть много вариантов</div>
			<div className="available-violations__card-title">Lorem Ipsum, но большинство из них имеет не всегда приемлемые модификации, например</div>
		</div>
	);
}
